function deduc(val) {
  var output = val - (val * 66) / 100;
  output = Math.round(output);
  return output;
}

function resetActiveCard(card) {
  for (var i = 0; i < 4; i++) card[i].classList.remove("active");
}

function setBtnHref(url) {
  document.getElementsByClassName("button-cta")[0].href = url;
}

function updateDefiscCustomValue(value) {
  var output = deduc(value);
  if (!isNaN(output)) {
    output = Math.round(output);
    document.getElementById("deduc").innerHTML = output + "€";
  } else {
    document.getElementById("deduc").innerHTML = "0€";
  }
}

function selectCard(n, amount) {
  console.log(n)
  var btn = document.getElementsByClassName("button-cta")[0];
  if (document.documentElement.clientWidth > 992) {
    var card = document.getElementsByClassName("card");
    var input = document.getElementsByClassName("custom-eq")[1];
  } else {
    var input = document.getElementsByClassName("custom-eq")[0];
    var card = document.getElementsByClassName("card-mb");
  }
  resetActiveCard(card);
  card[n].classList.add("active");
  document.getElementById("deduc").innerHTML = deduc(amount) + "€";
  if (amount == 0) {
    var output = parseInt(input.value);
    btn.href =
      "https://don.lejourduseigneur.com/?cid=3&reserved_code_media=LPCAR22&amount=" +
      output +
      "00";
    updateDefiscCustomValue(output);
  } else {
    btn.href =
      "https://don.lejourduseigneur.com/careme2024?reserved_code_media=LPCAR24&amount=" +
      amount +
      "00";
  }
  updateURL();
}
