function selectDate(n) {
  var item = document.getElementsByClassName("date-bubble");
  for (var i = 0; i < 7; i++) {
    item[i].classList.remove("active");
  }
  item[n].classList.add("active");
  changeDate(n);
}

function changeDate(n) {
  var date = [
    {
      quote: false,
      day: "18",
      month: "Février",
      title1: "Chapelle Marie-Auxiliatrice (75)",
      text: "Le 18 février sera le 1<sup>er</sup> dimanche du temps du Carême, que nous plaçons cette année sous la parole de Jésus&nbsp;: «&nbsp;Ta foi t’a sauvé&nbsp;».<br/><br/>La messe sera célébrée dans le Var, à La Crau, en la Chapelle Marie Auxiliatrice.<br/><br/>Le prédicateur sera le frère Franck Dubois, dominicain et le président le père Jean-Marie Petitclerc, salésien Don Bosco.",
    },
    {
      quote: true,
      day: "25",
      month: "Février",
      title1: "Église Saint-Jean-Baptiste (50)",
      text: "Le 25 février, second dimanche du Carême, nous retournerons au bord de la mer, dans la Manche, à Saint-Jean-le-Thomas, en l’église Saint-Jean-Baptiste.<br/><br/>Nous serons aux sources de notre foi. Le frère Thierry Hubert sera heureux de vous donner l’homélie, et le président sera le père Régis Rolet, curé de la paroisse Saint-Auguste Chapdelaine qui nous accueillera.",
    },
    {
      quote: true,
      day: "3",
      month: "MARS",
      title1: "Église Notre-Dame <br/>de Bon Secours (75)",
      text: "Le 3 mars, 3<sup>ème</sup> dimanche de Carême, la messe sera célébrée en l’église Notre-Dame de Bon Secours du 14ème arrondissement de Paris. <br/><br/>Le prédicateur sera le père Frédéric Ozanne, prêtre à la Mission de France, et le président Sébastien Violle.",
    },
    {
      quote: true,
      day: "10",
      month: "MARS",
      title1: "Église Saint-Martin en Belgique",
      text: "Le 10 mars, nos amis belges nous recevrons pour la messe depuis Assesse, dans le diocèse de Namur, en la belle église Saint-Martin. <br/><br/>L’abbé Reginaldo Lugarezi, curé, présidera cette célébration et Chanoine Joël Rochette prononcera l’homélie.",
    },
    {
      quote: true,
      day: "17",
      month: "MARS",
      title1: "Irlande",
      text: "Le 17 mars, 5<sup>ème</sup> et dernier dimanche de Carême et jour de la Saint-Patrick, <span class='font-weight-bold font-italic'>le Jour du Seigneur</span> vous propose dès 10h30 une matinée spéciale en Irlande. La messe sera célébrée par l'Eurovision et en direct depuis ORANMORE (Comté de Galway - Irlande) en l'église de l'Immaculée Conception.<br/>Le prédicateur sera le père Diarmuid Hogan.",
    },
    {
      quote: true,
      day: "24",
      month: "MARS",
      title1: "Chapelle de Sainte-Marie <br/>à Antony (92)",
      text: "Le 24 mars sera le dimanche des Rameaux et de la Passion du Seigneur. Dès 10h30,nous serons dans l’établissement scolaire de l’institution Sainte-Marie à Anthony (92). À 10h45, la messe des Rameaux vous sera offerte en la chapelle de cette institution.",
    },
    {
      quote: false,
      day: "31",
      month: "MARS",
      title1: "Profondeville, en Belgique, en direct de l’église Saint-Rémi",
      text: "Le 31 mars sera le dimanche de Pâques, à 11h, la messe sera diffusée en Eurovision depuis la Belgique dans l’église Saint-Rémi de Profondeville. Le président sera l’abbé Paul Yon, curé du lieu, tandis que l’homélie pascale nous sera donnée par le frère Didier Croonenberghs, dominicain.",
    },
  ];

  var bg = document.getElementsByClassName("date")[0];
  var title1 = document.getElementsByClassName("date-content-title1")[0];
  var text = document.getElementsByClassName("date-text")[0];
  var day = document.getElementsByClassName("date-content-day")[0];
  var month = document.getElementsByClassName("date-content-month")[0];
  var i = n + 1;

  if (document.documentElement.clientWidth < 768)
    bg.style.backgroundImage =
      "url('https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date" +
      i +
      "-mb.jpg')";
  else
    bg.style.backgroundImage =
      "url('https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date" +
      i +
      ".jpg')";
  if (document.documentElement.clientWidth < 992) {
    title1.innerHTML = date[n]["title1"];
  } else title1.innerHTML = date[n]["title1"];
  title1.className = date[n]["quote"]
    ? "date-content-title1 quote"
    : "date-content-title1";
  day.innerHTML = date[n]["day"];
  month.innerHTML = date[n]["month"];
  text.innerHTML = date[n]["text"];
}

function resize() {
  var bg = document.getElementById("date");
  var page = document.getElementsByClassName("page");

  var n;

  for (var i = 1; i < 8; i++) if (page[i].classList.contains("active")) n = i;
  if (document.documentElement.clientWidth < 768) {
    bg.setAttribute(
      "background-image",
      "url('https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date" +
        n +
        "-mb.jpg')"
    );
  } else {
    bg.setAttribute(
      "background-image",
      "url('https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date" +
        n +
        ".jpg')"
    );
  }

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  var navbar = document.getElementById("navigation");
  var header = document.getElementById("header");

  header.style.marginTop = navbar.offsetHeight + "px";
  header.style.height = vh - navbar.offsetHeight + "px";
}

let plus = document.getElementsByClassName("plus")[0];
let card = document.getElementsByClassName("plus-card")[0];
let card1 = document.getElementsByClassName("plus-card")[1];

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

var actualDate = 0;

function nextDate() {
  actualDate++;
  updateDate(actualDate);
}

function updateDate(actualDate) {
  if (actualDate <= 0) {
    actualDate = 0;
    document.getElementsByClassName("prev")[0].style.display = "none";
  } else {
    document.getElementsByClassName("prev")[0].style.display = "flex";
    selectDate(actualDate);
  }
  if (actualDate >= 6) {
    actualDate = 6;
    document.getElementsByClassName("next")[0].style.display = "none";
  } else {
    document.getElementsByClassName("next")[0].style.display = "flex";
    selectDate(actualDate);
  }
}

function prevDate() {
  actualDate--;
  updateDate(actualDate);
}
function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
}

let cardMb = false;

// function toggleText() {
//   let card = document.getElementById("card-mb");
//   let components = document.getElementsByClassName("d-none-card");
//   if (!cardMb) {
//     card.style.display = "flex";
//     components[0].style.display = "none";
//   } else {
//     card.style.display = "none";
//     components[0].style.display = "flex";
//   }
//   cardMb = !cardMb;
// }

function toggleText(action) {
  let btnPlus = document.getElementById("expand");
  let text = document.getElementById("date-text-container");
  if (action === "expand") {
    btnPlus.style.display = "none";
    text.style.display = "block";
  } else {
    btnPlus.style.display = "flex";
    text.style.display = "none";

  }
}
