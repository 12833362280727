function togglePlayer() {
  var player = document.getElementsByClassName("player")[0];
  var btn = document.getElementsByClassName("play")[0];
  var iframe = document.getElementById("iframe");

  if ("none" === player.style.display) {
    player.style.display = "flex";
    btn.style.display = "none";
    iframe.src =
      "https://www.youtube-nocookie.com/embed/UEC75PjX9HQ?si=kaEM6lwWwd2EqwEb";
  } else {
    btn.style.display = "inline-block";
    player.style.display = "none";
    iframe.src = "";
  }
}
