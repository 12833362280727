const dates = [
  new Date("2023-02-26T00:00:00.000Z"),
  new Date("2023-03-05T00:00:00.000Z"),
  new Date("2023-03-12T00:00:00.000Z"),
  new Date("2023-03-19T00:00:00.000Z"),
  new Date("2023-03-26T00:00:00.000Z"),
  new Date("2023-04-02T00:00:00.000Z"),
  new Date("2023-04-09T00:00:00.000Z"),
];

function callFunctionBetweenDates(start, end, i) {
  const today = new Date("");

  if (today >= start && today < end) {
    selectDate(i);
  }
}

for (let i = 0; i < dates.length - 1; i++) {
  const start = dates[i];
  const end = dates[i + 1];

  callFunctionBetweenDates(start, end, i);
}
