window.addEventListener("load", function (event) {
  const queryString = window.location.search;
  var btn = document.getElementsByTagName("a");
});

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function updateURL() {
  const queryString = window.location.search;
  var btn = document.getElementsByTagName("a");
//   const urlParams = new URLSearchParams(queryString);

  // for (var i = 0; i < 6; i++) {
  //     if (queryString === "")
  //         btn[i].setAttribute('href', btn[i].getAttribute('href'));
  //     else {
  //         if (!urlParams.has("from")) {
  //             btn[i].setAttribute('href', btn[i].getAttribute('href') + "&" + queryString.substring(1));
  //         } else {
  //             if (btn[i].getAttribute('href').includes("from")) {
  //                 btn[i].setAttribute('href', btn[i].getAttribute('href').replace("?from=LPAVT21", "?" + urlParams.toString()));
  //             }
  //         }
  //     }
  // }

  for (var i = 0; i < btn.length; i++) {
    const url = new URL(btn[i].href);
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      if (
        key === "utm_campaign" ||
        key === "utm_source" ||
        key === "utm_medium" ||
        key === "reserved_code_media"
      ) {
        url.searchParams.set(key, value);
      }
    });
    btn[i].href = url.href;
  }
}

setTimeout(function () {
  updateURL();
}, 10);

function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

function isParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        return true;
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return false;
}
